import React, { useState } from 'react';
import dot from '../../assets/img/raw/Ellipse 227.svg';
import phone from '../../assets/img/raw/call.svg';
import rafterBottom from '../../assets/img/raw/rafterBottom.svg';
import clock from '../../assets/img/raw/clock.svg';
import rafterUp from '../../assets/img/raw/rafterUp.svg';
import IMission from '../../types/mission.type';
import RowDetails from './MissionRowDetails.component';
import MissionStatusEnum, { MissionStatus } from '../../types/mission-status.enum';
import calendar from '../../assets/img/raw/calendar.svg';
import modify from '../../assets/images/modify.svg';
import cancel from '../../assets/images/cancel.svg';
import { useNavigate } from 'react-router-dom';
import EventBus from '../../common/EventBus';
import { BookingsTabs } from '../../types/bookings-tab.enum';
import Modal from '../modal/Modal.component';
import ValidationEstimate from '../block/validation_estimate/ValidationEstimate.component';
import { getAccessToken } from '../../utils/getAccessToken';
import QuoteService from '../../services/quotes/quote.service';
import { useMsal } from '@azure/msal-react';
import Cancel from '../block/cancel/Cancel.component';
import { MissionService } from '../../services/missions/mission.service';
import TripViewComponent, { TripViewMode } from '../trip/TripView.component';
import { transformTime } from '../../helpers/TransformDate.helper';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import useDateFormatting from '../../common/date/use-date-formatting.hook';

interface Props {
  mission: IMission;
  activeTab: number;
  handleReload?: () => void;
}

const MissionRow: React.FC<Props> = (props) => {
  const { t } = useTranslation('ns1', { i18n });
  const [expandedItems, setExpandedItems] = useState(false);
  const [modalQuotation, setModalQuotation] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [displayPrice, setDisplayPrice] = useState(false);
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleOpenPopInQuotation = () => {
    setModalQuotation(true);
  };

  const handleOpenPopInCancel = () => {
    setModalCancel(true);
  };

  const handleClosePopIn = () => {
    setModalQuotation(false);
    setModalCancel(false);
  };

  const {
    transformDateToLocaleString,
  } = useDateFormatting();

  const renderServiceType = (mission: IMission) => {
    const acceptedType = [
      'Transfert',
      'Transport',
      'Accueil',
      'Autre'
    ]
    if(mission.service != undefined){
      if(acceptedType.includes(mission.service.type)){
        return t(`components.booking.data.service.type.${mission.service.type}`);
      } else {
        return mission.service.type;
      }
    }
    return '';
  }

  const handleAcceptQuotation = async (command_id: number, mission_id: number): Promise<void> => {
    const confirmation_date = new Date();
    EventBus.dispatch('loading');
    getAccessToken(instance).then((token) => {
      QuoteService.acceptQuotation(token, command_id, mission_id, confirmation_date)
        .then(() => {
          handleClosePopIn();
          if (props.handleReload) {
            props.handleReload();
          }
          EventBus.dispatch('notLoading');
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const handleDenyQuotation = async (command_id: number, mission_id: number): Promise<void> => {
    const confirmation_date = new Date();
    EventBus.dispatch('loading');
    getAccessToken(instance).then((token) => {
      QuoteService.denyQuotation(token, command_id, mission_id, confirmation_date)
        .then(() => {
          handleClosePopIn();
          if (props.handleReload) {
            props.handleReload();
          }
          EventBus.dispatch('notLoading');
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  const handleCancelMission = async (command_id: number, mission_id: number): Promise<void> => {
    const confirmation_date = new Date();
    EventBus.dispatch('loading');
    getAccessToken(instance).then((token) => {
      MissionService.denyReservation(token, command_id, mission_id, confirmation_date)
        .then(() => {
          handleClosePopIn();
          if (props.handleReload) {
            props.handleReload();
          }
          EventBus.dispatch('notLoading');
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  const handleModifyClick = () => {
    if (props.mission.id) {
      const query_params = new URLSearchParams();
      query_params.append('missionId', props.mission.id.toString());
      const page = (props.activeTab === BookingsTabs.QuotationsRequests && props.mission.type === 'quote') ? 'estimate' : 'reservation';

      navigate(`/${page}?${query_params.toString()}`);
    }
  };

  return (
    <div
      data-testid='mission-row'
      className={expandedItems ? 'mission-row--opened' : 'mission-row'}
    >
      <div className={expandedItems ? 'mission-row__info--opened' : 'mission-row__info'}>
        { props.activeTab == BookingsTabs.QuotationsRequests && (
          <div className='mission-row__info__type'>
            {(props.mission.type === 'quote' || props.mission.type === 'booking') && (
              t(`components.booking.data.type.${props.mission.type}`)
            )}
          </div>
        )}
        <div className='mission-row__info__account'>{props.mission.accountName}</div>
        <div className='mission-row__info__ride'>
          {(props.mission.folderId ? props.mission.folderId + '-' : '') +
            (props.mission.missionNumber ?? '')}
        </div>
        <div className='mission-row__info__date'>
          <img src={calendar} alt='calendar' />
          <div className='text-color-dark fs-13'>{transformDateToLocaleString(props.mission.pickupDate, i18n.language, true)}</div>
        </div>
        <div className='mission-row__info__time'>
          <img className='time-img' src={clock} alt='clock' />
          <div className='text-color-dark fs-13'>{transformTime(props.mission.pickupTime)}</div>
          {props.mission.dropoffTime !== undefined && (
            <>
              <img className='dot' src={dot} alt='dot' />
              <div className='text-color-dark fs-13'>
                {transformTime(props.mission.dropoffTime)}
              </div>
            </>
          )}
        </div>
        <div className='mission-row__info__passenger'>
          {props.mission.passengers && props.mission.passengers[0] && (
            <>
              {props.mission.passengers[0].lastname
                ? props.mission.passengers[0].lastname + ' '
                : ''}
              {props.mission.passengers[0].firstname ? props.mission.passengers[0].firstname : ''}
            </>
          )}
        </div>
        <div className='mission-row__info__trip'>
          <TripViewComponent steps={props.mission.steps} mode={TripViewMode.column_light} />
        </div>
        <div className='mission-row__info__service'>
          {renderServiceType(props.mission)}
        </div>
        <div className='mission-row__info__vehicle'>
          {props.mission.vehicle?.type?.label ?? ''}
          <div className='mission-row__info__vehicle--immat text-color-dark2 fs-12'>
            {props.mission.vehicle?.registration ?? ''}
          </div>
        </div>
        <div className='mission-row__info__driver'>
          <div className=''>{props.mission.driver?.name ?? ''}</div>
          <div className='text-color-dark2 fs-12 phone'>
            {props.mission.driver?.phone && (
              <>
                <img src={phone} alt='phone' />
                <div>{props.mission.driver?.phone?.replace(/.{2}/g, '$& ') ?? ''}</div>
              </>
            )}
          </div>
        </div>
        {(props.activeTab === BookingsTabs.PastBookings ||
          props.activeTab === BookingsTabs.QuotationsRequests) &&
          displayPrice && (
            <div className='mission-row__info__price'>
              {props.mission.price != 'NaN' ? props.mission.price : ''}
            </div> //TODO : fix on back-end return
          )}
        {props.activeTab !== BookingsTabs.PastBookings &&
          props.activeTab !== BookingsTabs.BookingRequests && (
            <div className={`mission-row__info__actions`}>
              {props.mission.status !== MissionStatus.Closed &&
                props.mission.status !== MissionStatus.Billed && (
                  <>
                    {props.mission.canEdit && (
                      <img
                        data-testid='mission-row-modify'
                        className='mission-row__info_actions-button'
                        onClick={handleModifyClick}
                        onKeyUp={handleModifyClick}
                        src={modify}
                        alt='modify'
                      />
                    )}

                    {props.mission.canDelete && (
                      <img
                        data-testid='mission-row-cancel'
                        className='mission-row__info_actions-button'
                        onClick={handleOpenPopInCancel}
                        onKeyUp={handleOpenPopInCancel}
                        src={cancel}
                        alt='cancel'
                      />
                    )}
                  </>
                )}
            </div>
          )}

        <div
          className={`mission-row__info__status-circle`}
          style={MissionStatusEnum.getButtonStyleColor(props.mission.status)}
        ></div>

        {/**GESTION DES CHEVRONS */}
        {!expandedItems && (
          <div
            onClick={() => setExpandedItems(!expandedItems)}
            data-testid={'expande' + props.mission.id}
            style={{ cursor: 'pointer' }}
          >
            <img className='' src={rafterBottom} alt='rafterBottom' />
          </div>
        )}

        {expandedItems && (
          <div onClick={() => setExpandedItems(!expandedItems)}>
            <img
              className=''
              src={rafterUp}
              alt='rafterUp'
              data-testid={'unexpande' + props.mission.id}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}
      </div>
      {/**DEBUT MENU DEROULANT */}
      <div className='mission-row__info__more-info'>
        {expandedItems && (
          <RowDetails mission={props.mission} handlePopInQuotation={handleOpenPopInQuotation} />
        )}
      </div>

      {props.mission.folderId && (
        <Modal state={modalQuotation} handleClose={handleClosePopIn}>
          <ValidationEstimate
            quote={props.mission}
            id={parseInt(props.mission.folderId)}
            handleCancel={handleClosePopIn}
            handleApprove={handleAcceptQuotation}
            handleRefuse={handleDenyQuotation}
          />
        </Modal>
      )}

      {props.mission.folderId && (
        <Modal state={modalCancel} handleClose={handleClosePopIn}>
          <Cancel
            handleCancel={handleClosePopIn}
            kind={props.activeTab === BookingsTabs.QuotationsRequests ? 'quotes' : 'reservation'}
            mission={props.mission}
            id={parseInt(props.mission.folderId)}
            handleApprove={handleClosePopIn}
            handleRefuse={
              props.activeTab === BookingsTabs.QuotationsRequests
                ? handleDenyQuotation
                : handleCancelMission
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default MissionRow;
