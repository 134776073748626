import moment from 'moment/moment';
import 'moment/locale/fr';
import { t } from 'i18next';

const useDateFormatting = () => {
    const getDateWithoutTimeDifference = (date: Date): Date => {
        return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    }

    const getDateWithTimeDifference = (date: Date): Date => {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    }

    const transformDateToLocaleString = (date: any, language: string = 'en', withoutTimeDiff: boolean = false): string => {
        if (date !== undefined && !isNaN(new Date(date).getDate())) {
            let missionDate = new Date(date);
            if (withoutTimeDiff) {
                missionDate = getDateWithoutTimeDifference(missionDate);
            }
            const momentDate = moment(missionDate);
            momentDate.locale(language);
      
            return momentDate.format(t('date.format'));
        }

        return '';
    }

    return {
        getDateWithTimeDifference,
        getDateWithoutTimeDifference,
        transformDateToLocaleString,
    };
}

export default useDateFormatting;